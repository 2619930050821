<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback />
        {{ $t('ReportServiceActivity') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-text-field
            v-model="searchtext"
            dense
            outlined
            :label="`${$t('search')} (${$t('pressEnterForSearch')})`"
            @keypress.enter="addPayload(payload.start, payload.end)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-autocomplete
            v-model="customerGroupSelect"
            outlined
            dense
            :items="customerGroupList"
            item-text="customer_group_name"
            :label="$t('group_customers')"
            item-value="customer_group_id"
            @change="addPayload(payload.start, payload.end)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalListTable"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
        :no-results-text="$t('no_information')"
      >
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            v-if="item.order_id"
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.course_type`]="{ item }">
          <v-chip
            v-if="item.course_type"
            :color="
              item.course_type == $t('limited_time')
                ? 'primary'
                : item.course_type == $t('limited_day')
                ? 'warning'
                : 'info'
            "
            :class="
              item.course_type == $t('limited_time')
                ? `v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg primary--text`
                : item.course_type == $t('limited_day')
                ? `v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`
                : `v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg info--text`
            "
            small
          >
            <v-icon small class="me-1">
              {{
                item.course_type == $t('limited_time')
                  ? icons.mdiTimetable
                  : item.course_type == $t('limited_day')
                  ? icons.mdiClockOutline
                  : icons.mdiPill
              }}
            </v-icon>
            {{ item.course_type }}
          </v-chip>
        </template>
        <template v-slot:[`item.amount_cost`]="{ item }">
          <span :class="item.amount_cost == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.amount_cost }}
          </span>
        </template>
        <template v-slot:[`item.total_cost`]="{ item }">
          <span :class="item.amount_cost == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.total_cost }}
          </span>
        </template>
        <template v-slot:[`item.use_cost`]="{ item }">
          <span :class="item.amount_cost == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.use_cost }}
          </span>
        </template>
        <template v-slot:[`item.wait_cost`]="{ item }">
          <span :class="item.amount_cost == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.wait_cost }}
          </span>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiTimetable, mdiClockOutline, mdiPill, mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import Comeback from '../Comeback.vue'
import { i18n } from '@/plugins/i18n'
import { reportServiceActivity, removeComma } from '../useExcel'
import { sumdate } from '@/plugins/filters'
import customerGroup from '@/api/customer/customerGroup'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  setup() {
    const customerGroupSelect = ref('')
    const customerGroupList = ref([])
    const searchtext = ref('')
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('receipt_number'), value: 'order_id', width: 140 },
      {
        text: i18n.t('customer_code'),
        value: 'customer_id',
        width: 100,
      },
      { text: i18n.t('firstname_lastname'), value: 'customer_fullname', width: 200 },
      { text: i18n.t('gender'), value: 'customer_gender', width: 80 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 120 },
      { text: i18n.t('group_customers'), value: 'customer_group_name', width: 120 },
      //{ text: i18n.t('tag'), value: 'customer_tags', width: 180 },
      { text: i18n.t('code'), value: 'orderdetail_id', width: 100 },
      { text: i18n.t('course_list'), value: 'orderdetail_name', width: 230 },
      { text: i18n.t('type'), value: 'course_type', width: 150 },
      {
        text: `${i18n.t('all')}`,
        value: 'course_amount',
        width: 90,
        align: 'end',
      },
      {
        text: `${i18n.t('use_service')}`,
        value: 'course_use',
        width: 90,
        align: 'end',
      },
      {
        text: `${i18n.t('waiting_service')}`,
        value: 'course_wait',
        width: 90,
        align: 'end',
      },
      { text: i18n.t('last_used'), value: 'course_use_date', width: 120 },
      {
        text: i18n.t('price_times_courses'),
        value: 'amount_cost',
        width: 180,
        align: 'end',
      },
      {
        text: i18n.t('all'),
        value: 'total_cost',
        width: 180,
        align: 'end',
      },
      {
        text: i18n.t('use_service'),
        value: 'use_cost',
        width: 180,
        align: 'end',
      },
      {
        text: i18n.t('waiting_service'),
        value: 'wait_cost',
        width: 180,
        align: 'end',
      },
    ])
    const options = ref({})
    const totalPage = ref(0)
    const segmentId = ref(0)
    const totalListTable = ref(0)
    const exportLoading = ref(false)

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        customer_group_id: customerGroupSelect.value,
        searchtext: searchtext.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : +options.value.itemsPerPage,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { count, data } = await reportServiceActivity(payload)
      totalListTable.value = count
      options.value.page = 1
      options.value.itemsPerPage = -1
      dataTableList.value = data
      loading.value = false
    }

    customerGroup
      .customerGroupList({
        searchtext: '',
        lang: i18n.locale,
        per_page: '0',
        active_page: 1,
      })
      .then(res => {
        customerGroupList.value = res.data
        customerGroupList.value.unshift({
          customer_group_id: '',
          customer_group_name: i18n.t('all_customer_groups'),
        })
      })

    const mapData = data => {
      data = data.map(item => {
        delete item.order_id_pri
        delete item.shop_name
        delete item.shop_id_pri
        delete item.course_type_id

        return item
      })

      return data
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const dataExport1 = mapData(dataTableList.value)
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport1)))
      const fileName = `${i18n.t('ReportServiceActivity')}  ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)} .xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('ReportServiceActivity')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}  `],
        [
          i18n.t('list'),
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          i18n.t('number_of_courses'),
          '',
          '',
          '',
          i18n.t('course_value'),
          '',
          '',
        ],
        [
          '#',
          i18n.t('receipt_number'),
          i18n.t('customer_code'),
          i18n.t('firstname_lastname'),
          i18n.t('gender'),
          i18n.t('tel'),
          i18n.t('group_customers'),
          i18n.t('id_card'),
          i18n.t('email'),
          i18n.t('blood_type'),
          i18n.t('birthday'),
          i18n.t('age'),
          i18n.t('address'),
          //i18n.t('tag'),
          i18n.t('code'),
          i18n.t('course_list'),
          i18n.t('type'),
          i18n.t('all'),
          i18n.t('use_service'),
          i18n.t('waiting_service'),
          i18n.t('last_used'),
          `${i18n.t('price')} ${i18n.t('times_courses')}`,
          i18n.t('all'),
          i18n.t('use_service'),
          i18n.t('waiting_service'),
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'order_id',
          'customer_id',
          'customer_fullname',
          'customer_gender',
          'customer_tel',
          'customer_group_name',
          'customer_idcard',
          'customer_email',
          'customer_blood',
          'customer_birthdate',
          'customer_age',
          'customer_address',
          //'customer_tags',
          'orderdetail_id',
          'orderdetail_name',
          'course_type',
          'course_amount',
          'course_use',
          'course_wait',
          'course_use_date',
          'amount_cost',
          'total_cost',
          'use_cost',
          'wait_cost',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 24 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 16 } },
        { s: { r: 1, c: 17 }, e: { r: 1, c: 20 } },
        { s: { r: 1, c: 21 }, e: { r: 1, c: 24 } },
      ]
      const wscols = [
        { wch: 6 },
        { wch: 12 },
        { wch: 12 },
        { wch: 20 },
        { wch: 12 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 8 },
        { wch: 20 },
        { wch: 8 },
        { wch: 50 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('ReportServiceActivity')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      customerGroupSelect,
      customerGroupList,
      searchtext,
      payload,
      loading,
      dataTableList,
      columns,
      options,
      exportLoading,
      exportExcel,
      addPayload,
      searchReport,
      removeComma,
      mdiFileExcelOutline,
      totalPage,
      segmentId,
      totalListTable,
      icons: {
        mdiTimetable,
        mdiClockOutline,
        mdiPill,
      },
    }
  },
}
</script>
